<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <!-- <this-header /> -->
    <div class="anan-set-nav-conttainer mt-150">
      <validation-observer ref="simpleRules">
        <b-button
          class="btn btn-gradient-primary mb-1 mr-50"
          @click="$router.go(-1)"
        >
          <i class="fas fa-arrow-left mr-25" /> {{ $t('key-126') }}
        </b-button>
        <button
          class="btn btn-gradient-primary mb-1"
          @click="Submit()"
        >
          {{ $t('saveData') }}
        </button>
        <b-row>
          <b-col lg="6">
            <div class="card">
              <div class="header-cardx p-2">
                <h3 class="mb-0">
                  {{ $t('parcelInfo') }}
                </h3>
              </div>

              <div class="p-2">
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      label-for="h-parcel-code"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                    >
                      <template v-slot:label>
                        {{ $t('parcelCode') }} : <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="parcel code"
                        rules="required"
                      >
                        <b-form-input
                          id="h-parcel-code"
                          v-model="parcelCode"
                          :state="errors.length > 0 ? false:null"
                          @input="checkInput"
                        />
                        <small class="text-danger">{{ errors[0] ? $t('requiredField'): '' }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-parcel-code"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                    >
                      <template v-slot:label>
                        {{ $t('quantity') }} : <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="parcel code"
                        rules="required"
                      >
                        <b-form-input
                          id="h-parcel-code"
                          v-model="amount"
                          :state="errors.length > 0 ? false:null"
                          type="number"
                          class="w-25"
                          
                        />
                          <small class="text-danger">{{ errors[0] ? $t('requiredField'): '' }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-purchase-order"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                    >
                      <template v-slot:label>
                        {{ $t('key-22') }} :
                      </template>

                      <b-form-input
                        id="h-purchase-order"
                        v-model="purchaseOrder"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-keyword"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                    >
                      <template v-slot:label>
                        {{ $t('mnemonic') }} :
                      </template>

                      <b-form-input
                        id="h-keyword"
                        v-model="keyword"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-customerCode"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                    >
                      <template v-slot:label>
                        {{ $t('transport') }} :
                      </template>

                      <div class="demo-inline-spacing mt-n1">
                        <b-form-radio
                          v-model="transport"
                          name="some-radio9"
                          :value="1"
                          class="custom-control-primary"
                        >
                          {{ $t('truck') }}
                        </b-form-radio>

                        <b-form-radio
                          v-model="transport"
                          name="some-radio9"
                          :value="2"
                          class="custom-control-primary"
                        >
                          {{ $t('ship') }}
                        </b-form-radio>
                      </div>
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-checkProduct"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                    >
                      <template v-slot:label>
                        {{ $t('woodPacking') }} :
                      </template>

                      <b-form-checkbox
                        v-model="packbox"
                        name="check-button"
                        class="mt-50"
                        switch
                        inline
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-checkProduct"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                    >
                      <template v-slot:label>
                        {{ $t('checkOrder') }} :
                      </template>

                      <b-form-checkbox
                        v-model="checkProduct"
                        name="check-button"
                        class="mt-50"
                        switch
                        inline
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>

          <b-col lg="6">
            <div class="card">
              <div class="header-cardx p-2">
                <h3 class="mb-0">
                  {{ $t('chinaWarehouseAddress') }}
                </h3>
              </div>

              <div class="p-2">
                <p>
                  {{ $t('recipientName') }} : {{ username }}{{ packbox && checkProduct ? '(QC)(打木架)':packbox ? '(打木架)': checkProduct? '(QC)': '' }}
                </p>
                <p>
                  {{ $t('address') }} : {{ address.chi_warehouse_address }} ({{ username }}{{ packbox && checkProduct ? '(QC)(打木架)':packbox ? '(打木架)': checkProduct? '(QC)': '' }})
                </p>
                <p>
                  {{ $t('postalCode') }} : {{ address.chi_warehouse_zipcode }}
                </p>
                <p>
                  {{ $t('contactNumber') }} : {{ address.chi_warehouse_tel }}
                </p>

                <p class="text-danger">
                  {{ $t('remarks') }} : {{ $t('key-99') }}
                </p>
              </div>
            </div>
          </b-col>

        </b-row>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  // BFormTextarea,
  // BInputGroup,
  // BInputGroupAppend,
  // BFormDatepicker,
  BFormCheckbox,
  BFormRadio,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
// import vSelect from 'vue-select'
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'

export default {
  components: {
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    // BFormTextarea,
    // vSelect,
    // BInputGroup,
    // BInputGroupAppend,
    // BFormDatepicker,
    BFormCheckbox,
    BFormRadio,
    // ThisHeader,
  },
  data() {
    return {
      required,
      email,
      checkProduct: false,
      customerCode: null,
      packbox: false,
      parcelCode: null,
      purchaseOrder: null,
      transport: 1,
      keyword: null,
      status: null,
      remark: null,
      amount: 1,
      weight: 0,
      width: 0,
      height: 0,
      long: 0,
      ThinkPerKilo: 0,
      calPriceAuto: true,
      parcelExport: null,
      file: null,
      selectedFiles: [],
      uploadedImages: [],
      imagePreviewUrls: [],
      username: '',
      UserData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
  },
  mounted() {
    this.getaddress()
  },
  methods: {
    checkInput() {
      if (/\s/.test(this.parcelCode) || /\t/.test(this.parcelCode)) {
        this.parcelCode = this.parcelCode.replace(/\s/g, '').replace(/\t/g, '')
      }
    },
    Submit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (!this.parcelCode) {
            return this.SwalError(this.$t('key-309'))
          }
          if (!this.amount) {
            return this.SwalError(this.$t('key-309'))
          }
          const obj = {
            postnumber: this.parcelCode,
            product_amount: this.amount,
            tag_id: this.purchaseOrder,
            keyword: this.keyword,
            transportType: this.transport,
            packbox: this.packbox,
            checkproduct: this.checkProduct,
          }
          this.$http.post('/Tracking/storecustomer', obj)
            .then(response => {
              if (response.data.success) {
                this.Success('เพิ่มข้อมูลพัสดุเรียบร้อย')
                this.$router.push({ name: 'tracking' })
              } else {
                  this.SwalError(response.data.data)
              }
            })
            .catch(error => {
              console.log(error)
              this.SwalError(error)
            })
          // console.log(obj)
        }
      })
    },
    handleFileChanges(event) {
      const { files } = event.target
      this.imagePreviewUrls = []
      console.log(this.imagePreviewUrls)
      //   add to local storage
      localStorage.setItem(
        'imagePreviewUrls',
        JSON.stringify(this.imagePreviewUrls[0]),
      )

      for (let i = 0; i < files.length; i += 1) {
        const reader = new FileReader()
        reader.onload = e => {
          this.imagePreviewUrls.push(e.target.result)
        }
        reader.readAsDataURL(files[i])
      }
    },
    async getaddress() {
      try {
        this.$http.get('/Tracking/showaddress')
          .then(response => {
            this.address = response.data.data
            this.username = response.data.username
          })
      } catch (e) {
        console.log(e)
      }
    },
    addMoreImages() {
      this.$refs.fileInput.click()
      console.log(this.imagePreviewUrls)
    },
    handleFileChange(event) {
      const file = event.target.files[0]
      this.imgBackground = file
      this.previewImage(file)
    },
    previewImage(file) {
      const reader = new FileReader()
      reader.onload = () => {
        this.imagePreviewUrl = reader.result
      }
      reader.readAsDataURL(file)
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    showAdd() {
      this.$bvModal.show('add-addr')
    },
    showModal() {
      this.name = null
      this.phone = null
      this.address = null
      this.province = null
      this.district = null
      this.subdistrict = null
      this.postalcode = null
      this.$bvModal.show('modal-1')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
